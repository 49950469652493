.chat {}

.chat:hover {
   transform: scale(1.1);
   transition: .1s all;
   cursor: pointer;
}

.shopping-cart {
   font-size: 40px;
   color: #999;
   width: 45px;
   height: 39px;
}