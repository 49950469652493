.version-display {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: rgba(255, 255, 255, 1);
    color: rgba(0, 0, 0, 0.45);
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    z-index: 100;
}