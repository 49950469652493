.logo {
  height: 48px;
  margin: 5px 5px 5px 16px;
  background: rgba(255, 255, 255, 0.3);
}

.img-logo {
  cursor: pointer;
}

.img-logo:hover {
  transform: scale(1.01);
}

.ant-menu-title-content {
  white-space: normal !important;
  line-height: 18px !important;
}

.site-layout .site-layout-background {
  background: #fff;
}

.ant-layout-content {
  background-color: #fff;
}

.ant-col-info-user {
  display: flex !important;
  align-items: center;
  justify-content: flex-end;
}

.wrapper {
  padding: 0 5px 0 5px;
  min-height: 5%;
  overflow-y: scroll;
}

.sider-left {
  overflow: auto !important;
  position: fixed !important;
  left: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  z-index: 999 !important;
  margin: 64px 0 48px 0 !important;
  transform: translate(-100%);
}

.sider-left.active {
  transform: none;

}

.btn-active-menu {
  display: none;
}

.content-layout {
  margin-top: 64px;
}

.ant-layout-sider-trigger {}

.mobile-screen {}

.notification-wrapper {
  width: 100%;
}

.user-name-header {
  font-size: 20px;
  color: rgba(255, 255, 255, 0.65);
  cursor: pointer;
  user-select: none;
}

.user-name-header:hover {
  color: rgba(255, 255, 255, 1.65);
}

.btn-favicon-img {
  height: 12px;
}

.ant-dropdown {
  z-index: 99999 !important;
}

.ant-dropdown-menu-item {
  cursor: default !important;
}

.ant-dropdown-menu-item-active {
  background-color: rgba(0, 0, 0, 0) !important;
}

.notification-wrapper-children {
  max-height: 100%;
  min-height: 220px;
  padding: 5px;
  margin: 0 0 10px 0;
}

.list-body-notification {
  border-radius: 10px;
  padding: 0 10px;
  margin: 5px 0;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  color: #333333;
  transition: all 0.3s ease;
}

.list-body-notification:hover {
  background-color: #f0f8ff;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.3);
}

.ant-comment-actions li {
  width: 100%;
}

.notification-actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.notification-action-button {
  flex-grow: 1;
  margin: 0 5px;
  max-width: calc(50% - 10px);
  text-align: center;
}

.ant-drawer-content-wrapper {
  width: 500px !important;
}

@media screen and (max-width: 768px) {
  .sider-left {
    min-width: 100% !important;
    height: 93svh !important;
  }

  .sider-left.active .ant-layout-sider-children {
    overflow-y: scroll !important;
  }

  .btn-active-menu {
    display: flex;
    font-size: 25px;
  }

  .ant-layout-sider-trigger {
    display: none;
  }

  .logo {
    margin: 5px 5px 5px 5px;
  }

  .notification-wrapper {
    width: 100%;
  }

  .btn-favicon-img {
    height: 22px;
  }

  .btn-favicon.ant-btn-default {
    border: none;
    padding: 4px 0;
  }

  .btn-favicon .btn-favicon-text {
    display: none;
  }

  .notification-action-button {
    max-width: 100%;
    margin-bottom: 5px;
  }

  .drawer-notification {
    width: 100%;
  }

  .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}

.site-layout-background.wrapper {
  height: 100vh;
}

@media screen and (max-width: 375px) {
  .ant-space.css-dev-only-do-not-override-xu9wm8.ant-space-horizontal.ant-space-align-center {
    gap: 16px !important;
  }
}

@media screen and (max-width: 320px) {
  .logo .img-logo {
    width: 130px;
  }

  .ant-space.css-dev-only-do-not-override-xu9wm8.ant-space-horizontal.ant-space-align-center {
    gap: 12px !important;
  }
}

@media screen and (max-width: 290px) {
  .ant-space.css-dev-only-do-not-override-xu9wm8.ant-space-horizontal.ant-space-align-center {
    gap: 10px !important;
  }

  .btn-favicon-img {
    height: 16px;
  }

  .btn-notification {
    width: 35px !important;
  }

  .logo .img-logo {
    width: 110px;
  }

  .user-name-header.icon {
    font-size: 16px;
  }
}