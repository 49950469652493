iframe#webpack-dev-server-client-overlay {
  display: none !important
}

.imagePdf {
  cursor: pointer;
}

.imagePdf:hover {
  background-color: #aaa;
}

.ant-btn-primary {
  background: #1890E6;
}

* {
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  font-weight: 500;
}

html {
  overflow-y: hidden;
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}